.form-horizontal {
	&.form-bordered {
		& .form-group {
			border-bottom: 1px solid rgba(var(--app-component-border-color-rgb), .5);
			margin: 0;
			
			& .col-form-label {
				padding: $spacer;
				display: flex;
				align-items: center;
				
				@include media-breakpoint-down(md) {
					padding-bottom: $spacer * .5;
				}
				
				& + div {
					@if $enable-rtl {
						border-right: 1px solid rgba(var(--app-component-border-color-rgb), .5);
					} @else {
						border-left: 1px solid rgba(var(--app-component-border-color-rgb), .5);
					}
					@include media-breakpoint-down(md) {
						padding-top: 0;
						
						@if $enable-rtl {
							border-right: none;
						} @else {
							border-left: none;
						}
					}
				}
			}
			& > div {
				padding: $spacer;
			}
			&:last-child {
				border-bottom: 0;
			}
		}
	}
}