body {
	& .picker {
		display: block;
		font-family: $form-select-font-family;
		border: $form-select-border-width solid var(--app-component-border-color);
		background-color: var(--app-component-bg);
		padding: $form-select-padding-y $form-select-padding-x;
		min-height: $input-height;
		border-radius: $border-radius;
		
		& > .pc-element {
			margin: 0 $form-select-padding-x * .5 $form-select-padding-y 0;
			padding: rem(2px) rem(10px) !important;
			border: none;
			background: #{rgba(var(--app-component-border-color-rgb), .65)};
			color: var(--app-component-color);
			font-weight: $input-font-weight;
			border-radius: 30px;
			
			&:hover {
				background: #{rgba(var(--app-component-border-color-rgb), 1)};
				color: var(--app-component-color);
			}
			
			& .pc-close {
				position: relative;
				margin: 0;
				right: 0;
				top: 0;
				margin-left: $spacer * .5;
				opacity: .25;
				color: inherit;
				font-size: $font-size-base;
				
				@include fontawesome();
	
				&:after {
					content: '\f00d' !important;
				}
				&:hover {
					opacity: 0.5;
				}
			}
			& + .pc-select {
				padding-top: $form-select-padding-y;
				
				& .pc-element {
					margin-top: -$form-select-padding-y;
				}
			}
		}
		
		& .pc-select {
			display: block;
			width: auto;
			max-width: initial;
			min-width: initial;
			margin: (-$form-select-padding-y) (-$form-select-padding-x);
			
			& .pc-element {
				display: block;
				margin: 0;
				@include font-size($form-select-font-size);
				font-weight: $form-select-font-weight;
				line-height: $form-select-line-height;
				color: $form-select-color;
				background-color: transparent;
				background-image: escape-svg($form-select-indicator);
				background-repeat: no-repeat;
				background-size: $form-select-bg-size;
				appearance: none;
				border: none;
				
				@if $enable-rtl {
					background-position: left .75rem center;
					padding: $form-select-padding-y $form-select-padding-x $form-select-padding-y $form-select-indicator-padding;
				} @else {
					background-position: $form-select-bg-position;
					padding: $form-select-padding-y $form-select-indicator-padding $form-select-padding-y $form-select-padding-x;
				}
			}
			& .pc-list {
				border: none;
				padding: $dropdown-padding-y $dropdown-padding-x;
				background: var(--app-component-dropdown-bg);
				top: 100%;
				border-radius: $border-radius;
				box-shadow: $box-shadow;
				
				& input[type=search] {
					display: block;
					width: calc(100% - 20px);
					margin: 0 rem(10px) rem(5px);
					padding: $input-padding-y $input-padding-x;
					font-family: $input-font-family;
					@include font-size($input-font-size);
					font-weight: $input-font-weight;
					line-height: $input-line-height;
					color: var(--app-component-color);
					background-color: var(--app-component-bg);
					background-clip: padding-box;
					border: $input-border-width solid var(--app-component-border-color);
					appearance: none;
					transition: $input-transition;
					border-radius: $border-radius;

					&:focus {
						color: $input-focus-color;
						background-color: $input-focus-bg;
						border-color: $input-focus-border-color;
						outline: 0;
						box-shadow: $input-focus-box-shadow;
					}
				}
				
				& ul {
					& > li {
						padding: $nav-link-padding-y $nav-link-padding-x;
						background: none;
						color: var(--app-component-color);
						font-weight: $input-font-weight;
						
						&:nth-child(even) {
							background: none;
						}
						&:hover {
							background: var(--app-component-dropdown-hover-bg);
							color: var(--app-component-hover-color);
						}
					}
				}
			}
		}
	}
}